/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'

import { ArrowNarrowDownIcon, ArrowNarrowUpIcon } from '@heroicons/react/solid'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'

export interface ItemData {
  [key: string]: any
}

export interface TableConfigProps {
  keyName: keyof ItemData
  suffix?: JSX.Element | string
  renderCustomEl?: (item: any) => JSX.Element | string | number | undefined
  customRowClassFn?: (item: any) => string | undefined
  customColumnClass?: string
  sortable?: boolean
}

export interface TableProps {
  isLoading?: boolean
  data?: ItemData[]
  error?: Record<any, any>
  headers: string[]
  rowKeys: TableConfigProps[]
  onRowClick?: (item: any) => void
  loadMore?: (sortKey?: string, sortDirection?: 'asc' | 'desc') => Promise<void>
  readOnly?: boolean
  includeCheckboxes?: boolean
  onCheckboxChange?: (checkedIds: string[]) => void
  onSort?: (sortKey: string, sortDirection: 'asc' | 'desc') => void
  defaultSortKey?: string
  defaultSortDirection?: 'asc' | 'desc'
}

interface GenericColumnProps {
  item: ItemData
  config: TableConfigProps
}

type GenerateColumnContent = (
  config: TableConfigProps,
  item: ItemData,
) => JSX.Element | string | number

const columnContent: GenerateColumnContent = (config, item) => {
  const customRowClass =
    config.customRowClassFn && config.customRowClassFn(item)

  if (config.renderCustomEl) {
    const customElResult = config.renderCustomEl(item)
    if (!customElResult) return <div className="flex items-center">-</div>
    return (
      <div className="flex items-center w-full">
        <div
          className={classNames(
            'flex gap-x-1 w-full',
            'text-ppa/grayTextTable',
            customRowClass,
          )}
        >
          {customElResult}
          {config.suffix && <> {config.suffix}</>}
        </div>
      </div>
    )
  }

  return _.get(item, config.keyName) ? (
    <div
      className={classNames(
        'flex items-center',
        'text-ppa/grayTextTable',
        customRowClass,
      )}
    >
      {_.get(item, config.keyName)} {config.suffix}
    </div>
  ) : (
    '-'
  )
}

const Column: React.FC<GenericColumnProps> = ({ config, item }) => {
  return (
    <td
      className={classNames(
        'text-sm font-light px-5 py-3',
        config.customColumnClass,
      )}
    >
      {columnContent(config, item)}
    </td>
  )
}

const Table: React.FC<TableProps> = ({
  data,
  error,
  isLoading,
  headers,
  rowKeys,
  onRowClick,
  loadMore,
  readOnly = false,
  includeCheckboxes = false,
  onCheckboxChange,
  onSort,
  defaultSortKey,
  defaultSortDirection,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'table',
  })

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [checkedItems, setCheckedItems] = useState<string[]>([])

  const [sortKey, setSortKey] = useState<string | null>(defaultSortKey || null)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | null>(
    defaultSortDirection || null,
  )

  const handleRowCheckboxChange = (id: string) => {
    setCheckedItems((prev) =>
      prev.includes(id)
        ? prev.filter((itemId) => itemId !== id)
        : [...prev, id],
    )
  }

  const handleSelectAll = (isChecked: boolean) => {
    const newCheckedItems = isChecked ? data?.map((item) => item.id) || [] : []
    setCheckedItems(newCheckedItems)
    if (onCheckboxChange) {
      onCheckboxChange(newCheckedItems)
    }
  }

  const callLoadMore = useCallback(
    async (inView: boolean) => {
      /**
       * !loadMore => not tracking loadMore
       * isLoadingMore => it's already loading more
       * !inView => the element it's not in View yet
       */

      if (!loadMore || isLoadingMore || !inView) return

      setIsLoadingMore(true)

      await loadMore(sortKey || undefined, sortDirection || undefined)

      setIsLoadingMore(false)
    },
    [loadMore, isLoadingMore, sortKey, sortDirection],
  )

  const { ref } = useInView({
    onChange: callLoadMore,
    threshold: 0,
    rootMargin: '-50px 0px 0px 0px',
    triggerOnce: false,
  })

  const handleSort = (key: string) => {
    const newDirection =
      sortKey === key && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortKey(key)
    setSortDirection(newDirection)

    if (onSort) {
      onSort(key, newDirection)
    }
  }

  return (
    <div
      className={classNames(
        'w-full h-full',
        'relative z-[100] pb-5',
        'scroll-shadow-horizontal',
        'flex flex-col items-center justify-center',
      )}
    >
      <div className="w-full overflow-x-auto">
        <table className="bg-transparent w-full">
          <thead className="bg-ppa/tableTitleBackground border-b border-ppa/grayBorder">
            <tr>
              {includeCheckboxes && (
                <th className="flex py-4 px-5">
                  <input
                    type="checkbox"
                    onChange={(e) => handleSelectAll(e.target.checked)}
                    checked={
                      data &&
                      data.length > 0 &&
                      checkedItems.length === data.length
                    }
                  />
                </th>
              )}
              {rowKeys.map((item, index) => (
                <th
                  key={item.keyName.toString()}
                  className={classNames(
                    'text-sm text-ppa/tableTitleText text-left font-normal py-3 px-5',
                    item.sortable && 'cursor-pointer',
                    item.sortable && sortKey === item.keyName && 'underline',
                  )}
                  onClick={() => {
                    if (item.sortable) handleSort(item.keyName.toString())
                  }}
                >
                  <div className="flex items-center gap-x-1">
                    {item.sortable && sortKey !== rowKeys[index].keyName ? (
                      <div className="flex">
                        <ArrowNarrowUpIcon className="w-3 h-3 text-gray-500" />
                        <ArrowNarrowDownIcon className="w-3 h-3 -ml-1.5 text-gray-500" />
                      </div>
                    ) : item.sortable && sortDirection === 'asc' ? (
                      <div className="flex">
                        <ArrowNarrowUpIcon className="w-3 h-3 text-gray-600" />
                        <ArrowNarrowDownIcon className="w-3 h-3 -ml-1.5 text-gray-300" />
                      </div>
                    ) : (
                      item.sortable &&
                      sortDirection === 'desc' && (
                        <div className="flex">
                          <ArrowNarrowUpIcon className="w-3 h-3 text-gray-300" />
                          <ArrowNarrowDownIcon className="w-3 h-3 -ml-1.5 text-gray-600" />
                        </div>
                      )
                    )}
                    {headers[index]}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              !error &&
              data !== undefined &&
              data.length > 0 &&
              data.map((item, indexRow) => (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={indexRow}
                  onClick={(e) => {
                    if (
                      e.target instanceof HTMLInputElement &&
                      e.target.type === 'checkbox'
                    ) {
                      return
                    }
                    if (onRowClick) onRowClick(item)
                  }}
                  className={classNames(
                    !readOnly && 'hover:bg-ppa/tableRowHover',
                    checkedItems.includes(item.id) && 'bg-ppa/tableRowSelected',
                    'border-b border-grayBorder font-light text-ppa/tableRowText',
                    onRowClick && 'cursor-pointer',
                  )}
                >
                  {includeCheckboxes && (
                    <td className="px-5">
                      <input
                        type="checkbox"
                        checked={checkedItems.includes(item.id)}
                        onChange={() => {
                          handleRowCheckboxChange(item.id)
                        }}
                      />
                    </td>
                  )}
                  {rowKeys.map((rowKey) => (
                    <Column key={rowKey.keyName} config={rowKey} item={item} />
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {!error && !isLoadingMore && isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
          <Spinner className="animate-spin w-5 h-5" />
          <span className="text-base text-ppa/tableRowText font-normal py-3 ml-2">
            {t('loadingData')}
          </span>
        </div>
      )}

      {!isLoading && (data === undefined || data?.length === 0) && (
        <div className="flex items-center justify-center text-ppa/tableRowText border-b border-ppa/grayBorder w-full">
          <span className="text-base font-normal py-3">{t('noData')}</span>
        </div>
      )}

      {data && isLoadingMore && (
        <div className="flex flex-col items-center mt-2">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {data && data.length > 0 && !isLoadingMore && (
        <div ref={ref} className="absolute bottom-5 w-full h-[1px]" />
      )}
    </div>
  )
}

export default Table
