import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import usePPAGetList, {
  Filters,
} from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import SelectAsync, {
  SelectAsyncProps,
} from '../../../../../../components/atoms/select-async'

import { SelectOptions } from '../../../../../../components/atoms/select'
import { MeterCompany } from '../../../../../../types'

type SelectMeterProps = Omit<SelectAsyncProps, 'requestOptions'>

const SelectMeter: React.FC<SelectMeterProps> = ({
  value,
  type = 'multi',
  ...props
}) => {
  const { data, isLoading, applyFilters, loadMore } =
    usePPAGetList<MeterCompany>({
      dataKey: 'meters',
      path: '/core/private/consumer-meter/consumer/list',
      pagination: { limit: 50, offset: 0 },
      filters: [],
    })

  const [options, setOptions] = useState<SelectOptions[]>([])

  const formatOptions = useCallback(
    (meters: MeterCompany[]): SelectOptions[] => {
      const sorted = [...meters].sort((a, b) => a.name.localeCompare(b.name))
      return sorted.map((meter) => ({
        value: JSON.stringify(meter),
        label: meter.name,
        key: meter.name,
      }))
    },
    [],
  )

  const loadOptions = useCallback(
    async (inputValue: string): Promise<SelectOptions[]> => {
      try {
        const filters: Filters =
          inputValue.trim() === ''
            ? []
            : [{ field: 'search', operator: 'contains', value: inputValue }]
        const newData = await applyFilters(filters)
        return formatOptions(newData ?? [])
      } catch (err) {
        console.error('Error querying meters:', err)
        return []
      }
    },
    [applyFilters, formatOptions],
  )

  const debouncedLoadOptionsInternal = useDebouncedCallback(
    (
      inputValue: string,
      resolve: (opts: SelectOptions[]) => void,
      reject: (error: any) => void,
    ) => {
      loadOptions(inputValue).then(resolve).catch(reject)
    },
    300,
  )

  const debouncedLoadOptions = useCallback(
    (inputValue: string): Promise<SelectOptions[]> => {
      return new Promise((resolve, reject) => {
        debouncedLoadOptionsInternal(inputValue, resolve, reject)
      })
    },
    [debouncedLoadOptionsInternal],
  )

  const handleMenuScrollToBottom = useCallback(async () => {
    if (!isLoading) {
      await loadMore()
      if (data) {
        setOptions(formatOptions(data))
      }
    }
  }, [isLoading, loadMore, data, formatOptions])

  const handleChange = useCallback(
    (selectedOption: any) => {
      if (selectedOption) {
        if (type === 'multi') {
          const selectedArray = Array.isArray(selectedOption)
            ? selectedOption.map((option: any) => ({
                value: option.value,
                label: option.label,
              }))
            : [{ value: selectedOption.value, label: selectedOption.label }]
          props.onChange(selectedArray)
        } else {
          props.onChange({
            value: selectedOption.value,
            label: selectedOption.label,
          })
        }
      } else {
        props.onChange(null)
        applyFilters([])
      }
    },
    [props, type, applyFilters],
  )

  useEffect(() => {
    if (data) {
      setOptions(formatOptions(data))
    }
  }, [data, formatOptions])

  return (
    <SelectAsync
      {...props}
      value={value}
      type={type}
      testId="select-meters"
      loadOptions={debouncedLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onChange={handleChange}
      defaultOptions={options}
    />
  )
}

export default SelectMeter
