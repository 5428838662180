import {
  useCallback,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useDebouncedCallback } from 'use-debounce'

import useUser from '../../../hooks/use-user'
import usePPAGetList, { Filters } from '../../../hooks/use-ppa/use-ppa-get-list'

import SelectAsync, { SelectAsyncProps } from '../../atoms/select-async'
import { SelectOptions } from '../../atoms/select'

import { Company } from '../../../types'

export const parseCompanyToSelect = (company: Company): SelectOptions => {
  const label = (
    <div className="leading-tight text-sm">
      <strong className="font-medium">
        {company.name}&nbsp;({company.number})
      </strong>
      <span className="text-xs block truncate">
        {company?.address?.addressLine1}
      </span>
    </div>
  )

  return {
    label,
    value: JSON.stringify(company),
    key: company.id,
  }
}

export interface SelectCompaniesRef {
  refetch: () => void
}

type SelectPPACompaniesProps = Omit<SelectAsyncProps, 'requestOptions'>

const SelectPPACompanies = forwardRef<
  SelectCompaniesRef,
  SelectPPACompaniesProps
>((props, ref) => {
  const { user } = useUser()

  const { data, isLoading, applyFilters, loadMore, reset } =
    usePPAGetList<Company>({
      dataKey: 'companies',
      path: `/core/private/${user.appMetadata.userType.toLowerCase()}/company/list`,
      pagination: { offset: 0, limit: 100 },
      filters: [],
    })

  const [options, setOptions] = useState<SelectOptions[]>([])

  useImperativeHandle(ref, () => ({ refetch: () => reset() }), [reset])

  const loadOptions = useCallback(
    async (inputValue: string): Promise<SelectOptions[]> => {
      try {
        const filters: Filters =
          inputValue.trim() === ''
            ? []
            : [{ field: 'search', operator: 'contains', value: inputValue }]

        const newData = await applyFilters(filters)
        return newData
          ? newData.map((company: Company) => ({
              ...parseCompanyToSelect(company),
              key: company.id,
            }))
          : []
      } catch (err) {
        console.error(err)
        return []
      }
    },
    [applyFilters],
  )

  const debouncedLoadOptionswithLoadMore = useDebouncedCallback(
    (
      inputValue: string,
      resolve: (opts: SelectOptions[]) => void,
      reject: (error: any) => void,
    ) => {
      loadOptions(inputValue).then(resolve).catch(reject)
    },
    300,
  )

  const debouncedLoadOptions = useCallback(
    (inputValue: string): Promise<SelectOptions[]> => {
      return new Promise((resolve, reject) => {
        debouncedLoadOptionswithLoadMore(inputValue, resolve, reject)
      })
    },
    [debouncedLoadOptionswithLoadMore],
  )

  const handleMenuScrollToBottom = useCallback(async () => {
    if (!isLoading) {
      await loadMore()
      if (data) {
        const formatted = data.map((company: Company) => ({
          ...parseCompanyToSelect(company),
          key: company.id,
        }))
        setOptions(formatted)
      }
    }
  }, [isLoading, loadMore, data])

  const handleChange = useCallback(
    (selectedOption: any) => {
      if (selectedOption) {
        props.onChange({
          value: selectedOption.value,
          label: selectedOption.label,
        })
      } else {
        props.onChange(null)
        applyFilters([])
      }
    },
    [props, applyFilters],
  )

  useEffect(() => {
    if (data) {
      const formatted = data.map((company: Company) => ({
        ...parseCompanyToSelect(company),
        key: company.id,
      }))
      setOptions(formatted)
    }
  }, [data])

  return (
    <SelectAsync
      {...props}
      value={props.value}
      type="single"
      testId="select-ppa-company"
      loadOptions={debouncedLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onChange={handleChange}
      defaultOptions={options}
    />
  )
})

export default SelectPPACompanies
