import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AxiosError } from 'axios'

import dayjs from 'dayjs'

import usePPA from '../../../hooks/use-ppa'

import { dateFormats } from '../../../utils/data'

import { Site, TenderSubsidyName, TenderSubsidies } from '../../../types'
import type { Quote } from '../../../types/quote'

import PageTitle from '../../atoms/page-title'

import { SelectOptions } from '../../atoms/select'

import QuoteForm, { FormValues } from './quote-form'
import ShowQuote from './show-quote'

export type QuoteFormData = Omit<FormValues, 'subsidies'> & {
  subsidiesIncluded?: TenderSubsidyName[]
}

type QuoteRequest = {
  startDate: string
  endDate: string
  customEndDate?: boolean
  durationMonths?: string
  site: SelectOptions
  subsidies?: TenderSubsidies[]
}

type QuoteResponse = {
  pricing: Quote
} & QuoteRequest

export interface QuoteProps {
  data?: Quote
  error?: string
}

const QuotePage: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.quote',
  })
  const { fetchData } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const [quoteData, setQuoteData] = useState<QuoteProps>()

  const [quoteFormData, setQuoteFormData] = useState<QuoteFormData>()

  const handleFetchQuote = async (formData: QuoteRequest) => {
    if (isLoading || !formData) {
      setQuoteData({ error: 'NOT_AVAILABLE' })
      return
    }

    setIsLoading(true)

    try {
      const siteData = JSON.parse(formData.site.value)

      const subsidies: {
        roc: boolean
        rego: boolean
        fit: boolean
      } = {
        roc: false,
        rego: false,
        fit: false,
      }

      if (
        !Array.isArray(formData.subsidies) &&
        typeof formData.subsidies === 'object'
      ) {
        Object.entries(formData.subsidies).forEach(([key, value]: any) => {
          if (value?.enabled) {
            subsidies[key as keyof typeof subsidies] = true
          }
        })
      }

      const { startDate, durationMonths, customEndDate } = formData

      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths as any, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/quote/private/quote',
        body: {
          startDate,
          endDate,
          site: {
            technology: siteData.technology,
            capacity: parseFloat(`${siteData.capacity}`),
            voltage: parseFloat(`${siteData.voltage}`),
            rocBand: parseFloat(`${siteData.rocBand || 0}`),
            brokerFee: parseFloat(`${siteData.brokerFee}`),
            mpan: siteData.mpan,
          },
          imbalance: 0,
          administration: 0,
          subsidies,
        },
      })

      if (err || !response) throw err

      const formattedData: QuoteResponse = {
        ...formData,
        pricing: response.data.quote.pricing,
      }

      const userSubsidies: TenderSubsidyName[] = []
      if (formData.subsidies && !Array.isArray(formData.subsidies)) {
        const subsidiesObj = formData.subsidies as {
          rego?: { enabled: boolean }
          fit?: { enabled: boolean }
          roc?: { enabled: boolean }
        }

        if (subsidiesObj.rego?.enabled) {
          userSubsidies.push('REGO' as TenderSubsidyName)
        }
        if (subsidiesObj.fit?.enabled) {
          userSubsidies.push('FIT' as TenderSubsidyName)
        }
        if (subsidiesObj.roc?.enabled) {
          userSubsidies.push('ROC' as TenderSubsidyName)
        }
      }

      setQuoteFormData({
        startDate,
        endDate,
        site: siteData,
        subsidiesIncluded: userSubsidies,
      })

      setQuoteData({ data: formattedData.pricing })
    } catch (error: any) {
      console.error(error)

      if (error instanceof AxiosError) {
        const errorType = error.response?.data?.errorType
        const errorMessage = error.response?.data?.message

        if (
          errorType === 'PAYLOAD_ERROR' &&
          errorMessage?.includes('site.mpan')
        ) {
          setQuoteData({ error: 'MPAN_NOT_FOUND' })
          return
        }
      }

      setQuoteData({ error: 'NOT_AVAILABLE' })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-y-5 mt-1.5">
      <PageTitle title={t('title')} />
      <span className="text-ppa/title text-base font-normal">
        {t('subtitle')}
      </span>
      <div>
        {quoteData?.data ? (
          <ShowQuote
            quoteData={quoteData.data}
            quoteError={quoteData.error}
            isLoading={isLoading}
            onBack={() => setQuoteData({ data: undefined, error: undefined })}
            quoteFormData={quoteFormData as any}
            refetchQuote={() => handleFetchQuote(quoteFormData as any)}
          />
        ) : (
          <QuoteForm onSubmit={handleFetchQuote} isLoading={isLoading} />
        )}
      </div>
    </div>
  )
}

export default QuotePage
