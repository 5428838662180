import React from 'react'
import { useTranslation } from 'react-i18next'

import useUser from '../../../hooks/use-user'

import type { Quote as QuoteType } from '../../../types/quote'
import { TenderSubsidyName, Site } from '../../../types'

import Quote from '../../../pages/private/generator/export-tenders/components/quote'
import Button from '../../atoms/button'
import CreateTenderDrawer from './create-tender-drawer'

interface ShowQuoteProps {
  quoteData?: QuoteType
  quoteError?: string
  isLoading: boolean
  onBack: () => void
  refetchQuote: () => void
  quoteFormData: {
    startDate: string
    endDate: string
    site: Site
    subsidiesIncluded?: TenderSubsidyName[]
  }
}

const ShowQuote: React.FC<ShowQuoteProps> = ({
  quoteData,
  quoteError,
  isLoading,
  onBack,
  refetchQuote,
  quoteFormData,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.quote.showQuote',
  })

  const { user } = useUser<'GENERATOR' | 'ADMIN'>()

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)

  const { startDate, endDate, site, subsidiesIncluded } = quoteFormData

  const formatSubsidyLabel = (subsidies: string[]) => {
    const subsidyLabels: Record<string, string> = {
      REGO: t('greenCertificateName.rego'),
      FIT: t('greenCertificateName.fit'),
      FIT_EXPORT: t('greenCertificateName.fit'),
      ROC_BUYOUT: t('greenCertificateName.roc.buyout'),
      ROC_RECYCLE: t('greenCertificateName.roc.recycle'),
    }

    const uniqueLabels = new Set(
      subsidies.map((subsidy) => subsidyLabels[subsidy] || subsidy),
    )

    return Array.from(uniqueLabels).join(', ')
  }

  return (
    <>
      <div className="border-l border-ppa/sidebarProfileName mb-5">
        <div className="flex flex-col gap-y-2 pl-4 text-sm font-normal">
          <div className="flex flex-col gap-y-1">
            <span className="text-ppa/quoteSubtitle">{t('site')}</span>
            <span className="text-ppa/sidebarBackground pl-3">
              {site?.name || '-'}
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span className="text-ppa/quoteSubtitle">{t('startDate')}</span>
            <span className="text-ppa/sidebarBackground pl-3">
              {startDate || '-'}
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span className="text-ppa/quoteSubtitle">{t('endDate')}</span>
            <span className="text-ppa/sidebarBackground pl-3">
              {endDate || '-'}
            </span>
          </div>

          <div className="flex flex-col gap-y-1">
            <span className="text-ppa/quoteSubtitle">
              {t('greenCertificates')}
            </span>
            <span className="text-ppa/sidebarBackground pl-3">
              {subsidiesIncluded && subsidiesIncluded.length > 0
                ? formatSubsidyLabel(subsidiesIncluded)
                : t('noGreenCertificates')}
            </span>
          </div>
        </div>
      </div>

      <Quote
        quoteData={quoteData}
        quoteError={quoteError}
        isLoading={isLoading}
        refetchQuote={refetchQuote}
      />

      <div className="flex gap-x-5 mt-5">
        <div className="w-24">
          <Button variant="quaternary" fullWidth onClick={onBack}>
            {t('buttons.back')}
          </Button>
        </div>
        {user.appMetadata.userType === 'GENERATOR' && (
          <Button variant="primary" onClick={() => setIsDrawerOpen(true)}>
            {t('buttons.createTender')}
          </Button>
        )}
      </div>

      {user.appMetadata.userType === 'GENERATOR' && (
        <CreateTenderDrawer
          isOpen={isDrawerOpen}
          handleClose={() => setIsDrawerOpen(false)}
          quoteData={quoteData}
          quoteFormData={quoteFormData}
          onSuccess={() => {
            setIsDrawerOpen(false)
          }}
        />
      )}
    </>
  )
}

export default ShowQuote
