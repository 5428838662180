import { useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import usePPAGetList, {
  Filters,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import { isFeatureEnabled } from '../../../../utils/features'

import { parseSnippetAddress } from '../../../../utils'

import Table from '../../../../components/atoms/table'
import PageTitle from '../../../../components/atoms/page-title'

import SearchFilterMeter from './components/search-filter'

import { Site as MeterDTO, Company } from '../../../../types'

export type Meter = MeterDTO & { company: Company }

const defaultSort: { key: string; direction: 'asc' | 'desc' } = {
  key: 'name',
  direction: 'asc',
}

const Meters: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.meters',
  })

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>([])

  const [sortData, setSortData] = useState<{
    key: string | null
    direction: 'asc' | 'desc' | null
  }>(defaultSort)

  const {
    isLoading,
    loadMore,
    applyFilters,
    throwFeedbackError,
    data,
    error,
    reset,
  } = usePPAGetList<Meter>({
    dataKey: 'meters',
    path: '/core/private/consumer-meter/admin/list',
    filters,
    sort: sortData.key
      ? { field: sortData.key, order: sortData.direction! }
      : undefined,
  })

  const meters = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters(newFilters)
        setFilters(newFilters)
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter((item) => item.field === 'search')
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (sortData) {
      reset()
    }
  }, [sortData])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.text')} />
      </div>

      <div className="flex flex-col gap-y-5">
        {isFeatureEnabled('searchFilterMeter') && (
          <SearchFilterMeter
            onSubmit={fetchWithFilters}
            cleanFilters={handleCleanFilters}
          />
        )}

        <div className="relative z-20">
          <Table
            headers={[
              t('table.headers.meter'),
              t('table.headers.mpan'),
              t('table.headers.hhd'),
              t('table.headers.gridSize'),
              t('table.headers.annualConsumption'),
            ]}
            rowKeys={[
              {
                keyName: 'name',
                renderCustomEl: (item: Meter) => (
                  <div className="flex flex-col">
                    <span className="font-light">{item.name}</span>
                    {item.address ? (
                      <span className="font-extralight text-ellipsis overflow-hidden w-full max-w-40">
                        {parseSnippetAddress(item?.address)}
                      </span>
                    ) : (
                      <span className="font-extralight">{item?.location}</span>
                    )}
                  </div>
                ),
                sortable: true,
              },
              { keyName: 'mpan' },
              { keyName: 'hhd' },
              {
                keyName: 'gridConnectionSize',
                suffix: 'kVA',
                sortable: true,
              },
              { keyName: 'annualConsumption', suffix: 'MWh', sortable: true },
            ]}
            onRowClick={(meter) => navigate(`/meters/${meter.id}`)}
            isLoading={isLoading}
            error={error}
            data={meters}
            loadMore={loadMore}
            defaultSortKey={defaultSort.key}
            defaultSortDirection={defaultSort.direction}
            onSort={(sortKey, sortDirection) =>
              setSortData({ key: sortKey, direction: sortDirection })
            }
          />
        </div>
      </div>
    </div>
  )
}

export default Meters
