import React, { useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'
import { GroupBase, OptionsOrGroups } from 'react-select'
import { useDebouncedCallback } from 'use-debounce'

import { customStyles, selectConfig, SelectOptions } from '../select'
import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'

export type RequestOptionsFn = (
  inputSearch: string,
) => Promise<SelectOptions[] | undefined>

export interface SelectAsyncProps {
  onChange: (value?: any) => void
  onInputChange?: (inputValue?: string, actionMeta?: { action: string }) => void

  requestOptions?: RequestOptionsFn

  loadOptions?: (
    inputValue: string,
  ) => Promise<OptionsOrGroups<SelectOptions, GroupBase<SelectOptions>>> | void

  options?: SelectOptions[]
  defaultOptions?: SelectOptions[] | boolean

  onMenuScrollToBottom?: () => void
  type?: 'single' | 'multi'
  disabled?: boolean
  loading?: boolean
  placeholder?: string
  name: string
  value?: any
  testId?: string
}

const animatedComponents = makeAnimated()

export const CustomLoadingMessage = React.memo(
  ({ loading }: { loading: boolean }) => {
    return loading ? <Spinner className="animate-spin w-5 h-5" /> : null
  },
)

const customStyle = {
  ...customStyles,
  loadingMessage: (provided: any) => ({
    ...provided,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
}

const SelectAsync: React.FC<SelectAsyncProps> = ({
  onChange,
  onInputChange,
  requestOptions,
  loadOptions,
  options,
  defaultOptions,
  onMenuScrollToBottom,
  type = 'single',
  placeholder = selectConfig[type].placeholder,
  disabled,
  loading,
  name,
  value,
  testId = 'async-select-component',
}) => {
  const debouncedLoadOptions = useDebouncedCallback(
    (inputValue: string, callback: (res: unknown) => void) => {
      if (requestOptions) {
        requestOptions(inputValue)
          .then(callback)
          .catch((error) => {
            console.error(error)
            callback([])
          })
      } else {
        callback([])
      }
    },
    400,
  )

  const finalLoadOptions = loadOptions || debouncedLoadOptions

  const loadingMessageCallback = useCallback(() => {
    return <CustomLoadingMessage loading={loading as boolean} />
  }, [loading])

  return (
    <div className="w-full" data-testid={testId}>
      <AsyncSelect
        onChange={onChange}
        onInputChange={onInputChange}
        className="react-select-container"
        styles={customStyle as any}
        isClearable
        isDisabled={disabled}
        components={animatedComponents}
        isMulti={selectConfig[type].isMulti}
        placeholder={placeholder}
        loadOptions={finalLoadOptions as any}
        defaultOptions={defaultOptions || options}
        name={name}
        value={value || ''}
        defaultValue={value || undefined}
        onMenuScrollToBottom={onMenuScrollToBottom}
        isLoading={loading}
        loadingMessage={loadingMessageCallback}
        noOptionsMessage={() => 'No data...'}
      />
    </div>
  )
}

export default SelectAsync
