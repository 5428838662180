import React from 'react'

import classNames from 'classnames'

import { SleevedTenderDetailsProps } from '../details'

import TenderDetails from '../components/details'

import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'

const PendingSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
}) => {
  return (
    <section
      className={classNames(
        'flex flex-col',
        '2xl:flex-row 2xl:gap-x-5',
        'w-full',
      )}
    >
      <div className="2xl:w-1/2">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>

      <div
        className={classNames(
          '2xl:w-1/2',
          'flex flex-col gap-y-2',
          '2xl:border-l 2xl:mt-10 2xl:pl-10',
        )}
      >
        <div>
          <SleevedConsumerTenderDetailsDefList
            data={tenderInvitation.supplierTender.consumerTender as any}
          />
          <MeterDetailsDefList
            data={
              tenderInvitation.supplierTender.consumerTender
                .consumerMeter as any
            }
          />
        </div>
      </div>
    </section>
  )
}

export default PendingSleevedTenderDetails
