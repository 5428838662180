import { useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@heroicons/react/solid'

import usePPAGetList, {
  Filters,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import { parseSnippetAddress } from '../../../../utils'

import CreateMeter from './components/create-meter'

import Table from '../../../../components/atoms/table'
import Button from '../../../../components/atoms/button'
import PageTitle from '../../../../components/atoms/page-title'
import SearchFilterMeter from './components/search-filter'

import { isFeatureEnabled } from '../../../../utils/features'

import { Site as MeterDTO, Company } from '../../../../types'

export type Meter = MeterDTO & { company: Company }

const defaultSort: { key: string; direction: 'asc' | 'desc' } = {
  key: 'name',
  direction: 'asc',
}

const Meters: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters',
  })

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>([])

  const [sortData, setSortData] = useState<{
    key: string | null
    direction: 'asc' | 'desc' | null
  }>(defaultSort)

  const {
    isLoading,
    loadMore,
    applyFilters,
    reset,
    throwFeedbackError,
    data,
    error,
  } = usePPAGetList<Meter>({
    dataKey: 'meters',
    path: '/core/private/consumer-meter/consumer/list',
    filters,
    sort: sortData.key
      ? { field: sortData.key, order: sortData.direction! }
      : undefined,
  })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const meters = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters(newFilters)
        setFilters(newFilters)
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter((item) => item.field === 'search')
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  const onSuccess = async () => {
    setIsDrawerOpen(false)
    reset()
    try {
      await applyFilters(filters)
    } catch (err) {
      throwFeedbackError({
        err,
      })
    }
  }

  const handleClose = () => {
    setIsDrawerOpen(false)
    reset()
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (sortData) {
      reset()
    }
  }, [sortData])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.text')} />
        <div className="ml-7">
          <Button
            variant="primary"
            icon={<PlusIcon />}
            onClick={() => setIsDrawerOpen(true)}
          >
            {t('title.button')}
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-y-5">
        {isFeatureEnabled('searchFilterMeter') && (
          <SearchFilterMeter
            onSubmit={fetchWithFilters}
            cleanFilters={handleCleanFilters}
          />
        )}

        <div className="relative z-20">
          <Table
            headers={[
              t('table.headers.meter'),
              t('table.headers.mpan'),
              t('table.headers.hhd'),
              t('table.headers.gridSize'),
              t('table.headers.annualConsumption'),
            ]}
            rowKeys={[
              {
                keyName: 'name',
                renderCustomEl: (item: Meter) => {
                  return (
                    <div className="flex flex-col">
                      <span className="font-light">{item.name}</span>
                      {item.address ? (
                        <span className="font-extralight text-ellipsis overflow-hidden w-full max-w-40">
                          {parseSnippetAddress(item?.address)}
                        </span>
                      ) : (
                        <span className="font-extralight">
                          {item?.location}
                        </span>
                      )}
                    </div>
                  )
                },
                sortable: true,
              },
              { keyName: 'mpan' },
              { keyName: 'hhd' },
              { keyName: 'gridConnectionSize', suffix: 'kVA', sortable: true },
              { keyName: 'annualConsumption', suffix: 'MWh', sortable: true },
            ]}
            onRowClick={(meter) => navigate(`/meters/${meter.id}`)}
            isLoading={isLoading}
            error={error}
            data={meters}
            loadMore={loadMore}
            defaultSortKey={defaultSort.key}
            defaultSortDirection={defaultSort.direction}
            onSort={(sortKey, sortDirection) =>
              setSortData({ key: sortKey, direction: sortDirection })
            }
          />
        </div>
      </div>

      <CreateMeter
        onSuccess={onSuccess}
        isOpen={isDrawerOpen}
        handleClose={handleClose}
      />
    </div>
  )
}

export default Meters
