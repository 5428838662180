import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { ChevronLeftIcon } from '@heroicons/react/outline'

import { capitalizeText } from '../../../../../../utils'

import { container, breakPointsContainer } from '../../../../../../styles'

import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'

import TenderInvitationPill from '../../../../components/tender-invitation-pill'

import { mappedTenderStatus, AvailableRouteStatus } from '../../../types'

import { TenderStatus, SupplierTenderInvitation } from '../../../../../../types'

import SleevedTenderDetailsDefList from '../details-tender'
import SiteDetailsDefList from '../details-site'

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}
interface TenderDetailsSleevedProps {
  tenderInvitation: SupplierTenderInvitation
  buttons?: ButtonList[]
}

const SleevedTenderDetails: React.FC<TenderDetailsSleevedProps> = ({
  tenderInvitation,
  buttons,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })

  const navigate = useNavigate()

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() =>
            navigate(
              `/sleevedTenders/status/${
                mappedTenderStatus[
                  tenderInvitation.status as AvailableRouteStatus
                ]
              }`,
            )
          }
        >
          {t('backButton', {
            status: capitalizeText(
              tenderInvitation.supplierTender.status.replace(
                '_',
                ' ',
              ) as TenderStatus,
            ),
          })}
        </Button>
      </div>

      <div className="flex flex-col gap-y-8 w-full">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start">
            <h1 className="text-3xl font-semibold text-ppa/title mr-5 w-full">
              {tenderInvitation.supplierTender.shortId}
            </h1>
            <TenderInvitationPill
              status={tenderInvitation.supplierTender?.status as any}
              invitationStatus={tenderInvitation.status}
              bidStatus={tenderInvitation?.supplierTenderBid?.status}
            />
          </div>
        </div>

        <SleevedTenderDetailsDefList data={tenderInvitation.supplierTender} />
        <SiteDetailsDefList data={tenderInvitation.supplierTender.site} />

        {buttons && (
          <div className="flex items-center justify-start gap-x-1 w-full">
            {buttons.map((item) => (
              <div className="w-full flex max-w-[165px]" key={item.text}>
                <Button
                  {...item.props}
                  key={item.text}
                  onClick={() => item.onClick()}
                >
                  {item.text}
                </Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default SleevedTenderDetails
