import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import usePPA from '../../../hooks/use-ppa'

import { parseToFloatFormValues } from '../../../utils'
import { dateFormats } from '../../../utils/data'

import type { Quote } from '../../../types/quote'
import type {
  TenderSiteWithInvitation,
  TenderSubsidyName,
  Site,
} from '../../../types'

import Stepper from '../../atoms/stepper'
import Message from '../../atoms/message'
import SlidingDrawer from '../../molecules/sliding-drawer'
import { feedbackMessage } from '../../atoms/feedback'

import TenderForm from '../../../pages/private/generator/export-tenders/components/form-tender'
import TargetPriceForm from '../../../pages/private/generator/export-tenders/components/form-target-price'
import { QuoteProps } from '.'

type Tender = TenderSiteWithInvitation
type ParsedSubsidies = {
  REGO?: boolean
  ROC?: boolean
  ROC_BUYOUT?: number
  ROC_RECYCLE?: number
  FIT?: boolean
  FIT_EXPORT?: boolean
}

interface CreateTenderDrawerProps {
  isOpen: boolean
  handleClose: () => void
  quoteData?: Quote
  quoteFormData: {
    startDate: string
    endDate: string
    site?: Site
    subsidiesIncluded?: TenderSubsidyName[]
  }
  onSuccess: () => void
}

const CreateTenderDrawer: React.FC<CreateTenderDrawerProps> = ({
  isOpen,
  handleClose,
  quoteData,
  quoteFormData,
  onSuccess,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.tenders',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [createdTender, setCreatedTender] = useState<Tender | undefined>()

  const [quoteProps, setQuoteProps] = useState<QuoteProps | undefined>()

  const {
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    site: defaultSite,
    subsidiesIncluded,
  } = quoteFormData

  const handleFetchQuote = async (tender = createdTender) => {
    if (isLoading || !tender) {
      setQuoteProps({ error: 'NOT_AVAILABLE' })
      return
    }

    setIsLoading(true)

    try {
      const subsidies: {
        roc: boolean
        rego: boolean
        fit: boolean
        fitExport: boolean
      } = {
        roc: false,
        rego: false,
        fit: false,
        fitExport: false,
      }

      if (tender.subsidies.length > 0) {
        tender.subsidies.forEach((item) => {
          if (item.toggled === true) {
            if (item.name === 'FIT') {
              subsidies.fit = true
            } else if (item.name === 'REGO') {
              subsidies.rego = true
            } else if (item.name === 'ROC') {
              subsidies.roc = true
            }
          }
        })
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/quote/private/quote',
        body: {
          startDate: tender.startDate,
          endDate: tender.endDate,
          site: {
            technology: tender.site.technology,
            capacity: parseFloat(`${tender.site.capacity}`),
            voltage: parseFloat(`${tender.site.voltage}`),
            rocBand: parseFloat(`${tender.site?.rocBand || 0}`),
            mpan: tender.site.mpan,
            brokerFee: parseFloat(`${tender.site.brokerFee}`),
          },
          imbalance: 0,
          administration: 0,
          subsidies,
        },
      })

      if (err || !response) throw err

      setQuoteProps({ data: response.data.quote.pricing })
      setIsLoading(false)
    } catch (error: any) {
      console.error(error)
      setIsLoading(false)
      if (error) {
        const errorType = error.response?.data?.errorType
        const errorMessage = error.response?.data?.message

        if (errorType === 'PAYLOAD_ERROR') {
          if (errorMessage) {
            if (
              errorMessage.includes('site.mpan') &&
              errorMessage.includes('is not allowed to be empty')
            ) {
              setQuoteProps({ error: 'MPAN_NOT_FOUND' })
              return
            }
          }
        }
      }

      setQuoteProps({ error: 'NOT_AVAILABLE' })
    }
  }

  const onTenderCreateSubmit = async (formData: any) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const siteIds = formData.sites.map(
        (site: any) => JSON.parse(site.value).id,
      )

      let supplierIds: string[] = []
      if (
        formData.participatingSuppliers &&
        formData.participatingSuppliers.length > 0
      ) {
        supplierIds = formData.participatingSuppliers.map(
          (item: string) => JSON.parse(item).id,
        )
      }

      const { fit, rego, roc } = formData.subsidies
      const subsidies: ParsedSubsidies = { REGO: !!rego?.enabled }
      const tenderCreatedSubsidies = []

      if (rego?.enabled)
        tenderCreatedSubsidies.push({ name: 'REGO', toggled: true })
      if (roc?.enabled) {
        tenderCreatedSubsidies.push({ name: 'ROC', toggled: true })
        subsidies.ROC = true
      } else if (fit?.enabled) {
        tenderCreatedSubsidies.push({ name: 'FIT', toggled: true })
        subsidies.FIT = true
      } else {
        subsidies.ROC = false
        subsidies.FIT = false
      }

      const { startDate, durationMonths, customEndDate, otherRequirements } =
        formData
      let { endDate } = formData
      if (customEndDate) {
        endDate = formData.endDate
      } else {
        endDate = dayjs(startDate)
          .add(durationMonths, 'months')
          .subtract(1, 'month')
          .endOf('month')
          .format(dateFormats.aws)
      }

      const { response, error: err } = await fetchData({
        method: 'POST',
        url: '/core/private/tender',
        body: {
          startDate,
          endDate,
          siteIds,
          supplierIds,
          subsidies,
          otherRequirements,
        },
      })

      if (err || !response) throw err

      if (siteIds.length > 1) {
        handleClose()
        onSuccess()
        return
      }

      const newCreatedTender = {
        ...response.data.tenders[0],
        subsidies: tenderCreatedSubsidies,
      }
      setCreatedTender(newCreatedTender)

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t(
            'drawerCreateUpdateTender.form.create.onSubmit.success.description',
          ),
        },
        'success',
      )

      setCurrentStep(1)
      await handleFetchQuote(newCreatedTender)
    } catch (err) {
      throwFeedbackError({ err, context: 'tender' })
    } finally {
      setIsLoading(false)
    }
  }

  const resetDrawer = () => {
    handleClose()
    setCurrentStep(0)
    setCreatedTender(undefined)
  }

  const handleUpdateTargetPrice = async (formData: any) => {
    if (!createdTender?.id) {
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: t(
            'drawerTargetPrice.edit.onSubmit.error.unableToLoadTender',
          ),
        },
        'error',
      )
      return
    }

    if (isLoading) return
    setIsLoading(true)

    try {
      const { response, error: err } = await fetchData({
        method: 'PATCH',
        url: `/core/private/tender/${createdTender.id}/update-target-price`,
        body: {
          targetPrice: parseToFloatFormValues(`${formData.targetPrice}`),
          minimumPrice: parseToFloatFormValues(`${formData.minimumPrice}`) || 0,
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('drawerTargetPrice.edit.onSubmit.success.title'),
          description: t('drawerTargetPrice.edit.onSubmit.success.description'),
        },
        'success',
      )
      resetDrawer()
      onSuccess()
    } catch (err) {
      throwFeedbackError({ err, context: 'tender' })
    } finally {
      setIsLoading(false)
    }
  }

  const defaultFormData = useMemo((): any => {
    if (!quoteData) return undefined

    const defaultSubsidies = {
      roc: { enabled: false },
      fit: { enabled: false },
      rego: { enabled: false },
    }
    if (Array.isArray(subsidiesIncluded)) {
      subsidiesIncluded.forEach((subsidy: TenderSubsidyName) => {
        const formattedSubsidy = subsidy.toUpperCase().replace(/_/g, '')

        if (formattedSubsidy === 'REGO') {
          defaultSubsidies.rego.enabled = true
        } else if (formattedSubsidy === 'FIT') {
          defaultSubsidies.fit.enabled = true
        } else if (formattedSubsidy.startsWith('ROC')) {
          defaultSubsidies.roc.enabled = true
        }
      })
    }

    let sites: { value: string; label: string }[] = []

    if (Array.isArray(defaultSite)) {
      sites = defaultSite.map((s) => ({
        value: JSON.stringify({
          id: s.id,
          rocBand: s.rocBand,
          capacity: s.capacity,
          technology: s.technology,
          voltage: s.voltage,
          brokerFee: s.brokerFee,
          mpan: s.mpan,
          name: s.name,
        }),
        label: `${s.name} (${s.technology})`,
      }))
    } else if (defaultSite) {
      sites = [
        {
          value: JSON.stringify({
            id: defaultSite.id,
            rocBand: defaultSite.rocBand,
            capacity: defaultSite.capacity,
            technology: defaultSite.technology,
            voltage: defaultSite.voltage,
            brokerFee: defaultSite.brokerFee,
            mpan: defaultSite.mpan,
            name: defaultSite.name,
          }),
          label: `${defaultSite.name} (${defaultSite.technology})`,
        },
      ]
    }

    return {
      startDate: dayjs(defaultStartDate).format(dateFormats.aws),
      endDate: dayjs(defaultEndDate).format(dateFormats.aws),
      durationMonths: { value: '6', label: '6' },
      customEndDate: true,
      sites,
      participatingSuppliers: [],
      subsidies: defaultSubsidies,
      otherRequirements: '',
    }
  }, [quoteData])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('drawerCreateUpdateTender.form.create.title')}
        </h1>
        <Message variant="info" size="text-sm">
          {t('drawerCreateUpdateTender.form.create.info.0')}
          <br />
          {t('drawerCreateUpdateTender.form.create.info.1')}
        </Message>
        <Stepper
          steps={[
            t('drawerCreateUpdateTender.stepper.details'),
            t('drawerCreateUpdateTender.stepper.targetPrice'),
          ]}
          activeStep={currentStep}
          setActiveStep={setCurrentStep}
          disabledStep={currentStep === 0 || currentStep === 1}
        />
        <section className="flex flex-1 w-full">
          {currentStep === 0 && (
            <TenderForm
              isLoading={isLoading}
              onCancel={handleClose}
              onSubmit={onTenderCreateSubmit}
              defaultValues={defaultFormData}
            />
          )}
          {currentStep === 1 && (
            <TargetPriceForm
              isLoading={isLoading}
              quote={quoteProps?.data}
              quoteError={quoteProps?.error}
              onSubmit={handleUpdateTargetPrice}
              refetchQuote={handleFetchQuote}
              onSkip={resetDrawer}
              tender={createdTender}
            />
          )}
        </section>
      </div>
    </SlidingDrawer>
  )
}

export default CreateTenderDrawer
