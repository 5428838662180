import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import useUser from '../../../../../../hooks/use-user'
import usePPAGetList, {
  Filters,
} from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import SelectAsync, {
  SelectAsyncProps,
} from '../../../../../../components/atoms/select-async'
import { SelectOptions } from '../../../../../../components/atoms/select'

import { capitalizeText } from '../../../../../../utils'

type SelectSiteProps = Omit<SelectAsyncProps, 'requestOptions'>

const defaultFilters: Filters = [
  { field: 'archivedAt', operator: 'equals', value: null },
]

const SelectSite: React.FC<SelectSiteProps> = ({
  value,
  type = 'multi',
  ...props
}) => {
  const { t: tUtils } = useTranslation('private/index', { keyPrefix: 'utils' })
  const { user } = useUser<'GENERATOR' | 'ADMIN'>()

  const { data, isLoading, applyFilters, loadMore } = usePPAGetList({
    dataKey: 'sites',
    path:
      user.appMetadata.userType === 'GENERATOR'
        ? '/core/private/site/list'
        : '/core/private/site/list-by-admin',
    pagination: { limit: 50, offset: 0 },
    filters: [...defaultFilters],
  })

  const [options, setOptions] = useState<SelectOptions[]>([])

  const formatOptions = useCallback(
    (sites: any[]): SelectOptions[] => {
      return sites.map((site) => {
        const {
          id,
          rocBand,
          capacity,
          technology,
          voltage,
          brokerFee,
          mpan,
          name,
        } = site

        const techAbbreviation = capitalizeText(
          tUtils(`technology.${technology}.abbreviation`),
        )

        let selectedLabel: string | undefined
        if (value && value.length > 0) selectedLabel = value[0].label

        let isDisabled = false
        if (selectedLabel && !selectedLabel.includes(technology)) {
          isDisabled = true
        }

        return {
          value: JSON.stringify({
            id,
            rocBand,
            capacity,
            technology,
            voltage,
            brokerFee,
            mpan,
            name,
            isDisabled,
          }),
          label: `${name} (${techAbbreviation})`,
          key: `${name} (${techAbbreviation})`,
        }
      })
    },
    [tUtils],
  )

  const loadOptions = useCallback(
    async (inputValue: string): Promise<SelectOptions[]> => {
      try {
        const filters =
          inputValue.trim() === ''
            ? []
            : [{ field: 'search', operator: 'contains', value: inputValue }]
        const newFilters = [...defaultFilters, ...filters]

        const newData = await applyFilters(newFilters)
        return formatOptions(newData ?? [])
      } catch (err) {
        console.error('Error querying sites:', err)
        return []
      }
    },
    [applyFilters, formatOptions],
  )

  const debouncedLoadOptionswithLoadMore = useDebouncedCallback(
    (
      inputValue: string,
      resolve: (opts: SelectOptions[]) => void,
      reject: (error: any) => void,
    ) => {
      loadOptions(inputValue).then(resolve).catch(reject)
    },
    300,
  )

  const debouncedLoadOptions = useCallback(
    (inputValue: string): Promise<SelectOptions[]> => {
      return new Promise((resolve, reject) => {
        debouncedLoadOptionswithLoadMore(inputValue, resolve, reject)
      })
    },
    [debouncedLoadOptionswithLoadMore],
  )

  const handleChange = useCallback(
    (selectedOption: any) => {
      if (selectedOption) {
        if (type === 'multi') {
          const selectedArray = Array.isArray(selectedOption)
            ? selectedOption.map((option: any) => ({
                value: option.value,
                label: option.label,
              }))
            : [{ value: selectedOption.value, label: selectedOption.label }]
          props.onChange(selectedArray)
        } else {
          props.onChange({
            value: selectedOption.value,
            label: selectedOption.label,
          })
        }
      } else {
        props.onChange(null)
        applyFilters(defaultFilters)
      }
    },
    [props, type, applyFilters],
  )

  const handleMenuScrollToBottom = useCallback(async () => {
    if (!isLoading) {
      await loadMore()
      if (data) {
        setOptions(formatOptions(data))
      }
    }
  }, [isLoading, loadMore, data, formatOptions])

  useEffect(() => {
    if (data) {
      setOptions(formatOptions(data))
    }
  }, [data, formatOptions])

  return (
    <SelectAsync
      {...props}
      value={value}
      type={type}
      testId="select-sites"
      loadOptions={debouncedLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onChange={handleChange}
      defaultOptions={options}
      loading={isLoading}
    />
  )
}

export default SelectSite
