import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import TenderDetails from '../components/details'
import BidDetails from '../components/details-bid'
import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'

import { SleevedTenderDetailsProps } from '../details'

const ExecutedSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { isLoading, throwFeedbackError, data, error } = usePPAGetDetails<any>({
    dataKey: 'supplierTenderBid',
    path: `/core/private/supplier-tender-bid/supplier/details/${tenderInvitation.supplierTenderBid.id}`,
  })

  const bidDetails: any | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  return (
    <section
      className={classNames(
        'flex flex-col',
        '2xl:flex-row 2xl:gap-x-5',
        'w-full',
      )}
    >
      <div className="2xl:w-1/2">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>

      <div
        className={classNames(
          '2xl:w-1/2',
          'flex flex-col gap-y-2',
          '2xl:border-l 2xl:mt-10 2xl:pl-10',
        )}
      >
        <BidDetails bid={bidDetails} refetchBidList={refetch} />
        <div>
          <SleevedConsumerTenderDetailsDefList
            data={tenderInvitation.supplierTender.consumerTender as any}
          />
          <MeterDetailsDefList
            data={
              tenderInvitation.supplierTender.consumerTender
                .consumerMeter as any
            }
          />
        </div>
      </div>
    </section>
  )
}

export default ExecutedSleevedTenderDetails
