import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PlusIcon } from '@heroicons/react/solid'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'
import useRegisterCompany from '../../../../../hooks/use-register-company'

import { Company, Address } from '../../../../../types'

import { parseSnippetAddress } from '../../../../../utils'

import { feedbackMessage } from '../../../../../components/atoms/feedback'
import PageTitle from '../../../../../components/atoms/page-title'
import Button from '../../../../../components/atoms/button'
import Table from '../../../../../components/atoms/table'

const tableRowKeys = [
  {
    keyName: 'name',
    sortable: true,
  },
  {
    keyName: 'number',
  },
  {
    keyName: 'address',
    renderCustomEl: (item: Company) => {
      return parseSnippetAddress(item?.address as Address | undefined)
    },
  },
]

const defaultSort: { key: string; direction: 'asc' | 'desc' } = {
  key: 'name',
  direction: 'asc',
}

const MetersCompanyList: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.companyList',
  })

  const navigate = useNavigate()

  const [sortData, setSortData] = useState<{
    key: string | null
    direction: 'asc' | 'desc' | null
  }>(defaultSort)

  const { isLoading, loadMore, throwFeedbackError, data, error, reset } =
    usePPAGetList<Company[]>({
      dataKey: 'companies',
      path: '/core/private/consumer/company/list',
      pagination: {
        offset: 0,
        limit: 10,
      },
      sort: sortData.key
        ? { field: sortData.key, order: sortData.direction! }
        : undefined,
    })

  const companies = useMemo(() => {
    if (!data || isLoading || error) return []

    return data
  }, [data, isLoading, error])

  const { drawer, openDrawer } = useRegisterCompany({
    onSuccess: async (company) => {
      await reset()
      feedbackMessage(
        {
          title: t('registerCompany.onSuccessMessage.title', {
            companyName: company.name,
          }),
        },
        'success',
      )
    },
  })

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (sortData) {
      reset()
    }
  }, [sortData])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title')} />
        <div className="ml-7">
          <Button
            variant="primary"
            type="button"
            icon={<PlusIcon />}
            onClick={openDrawer}
          >
            {t('addNewCompany')}
          </Button>
        </div>
      </div>
      <div className="relative z-20">
        <Table
          headers={[
            t('table.headers.name'),
            t('table.headers.number'),
            t('table.headers.address'),
          ]}
          rowKeys={tableRowKeys}
          onRowClick={(company: Company) =>
            navigate(`/meters/companies/${company.id}`)
          }
          isLoading={isLoading}
          error={error}
          data={companies}
          loadMore={loadMore}
          defaultSortKey={defaultSort.key}
          defaultSortDirection={defaultSort.direction}
          onSort={(sortKey, sortDirection) =>
            setSortData({ key: sortKey, direction: sortDirection })
          }
        />
      </div>
      {drawer}
    </div>
  )
}

export default MetersCompanyList
