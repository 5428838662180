import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'

import usePPA, { ThrowFeedbackErrorFn, PPAError } from '../index'

export type Filters = {
  field: string
  operator?: string
  value: any
}[]

export type Sort = {
  field: string
  order: 'asc' | 'desc'
}

interface GenericQuery {
  filters?: Filters
  pagination?: {
    offset: number
    limit: number
  }
  sort?: Sort
}

type Query = GenericQuery & Record<string, any>

type Pagination = {
  offset: number
  limit: number
}

type Params = {
  filters?: Filters
  pagination?: Pagination
  sort?: Sort
} & Record<string, any>

type UsePPAGetListProps = {
  dataKey: string
  path: string
  params?: Params
  filters?: Filters
  pagination?: Pagination
  sort?: Sort
}

type FetchDataFn<DataType = any> = (
  props: UsePPAGetListProps,
) => Promise<DataType[] | undefined>

interface UsePPAGetListData<DataType = any> {
  data?: DataType[]
  isLoading: boolean
  error?: PPAError
  reset: () => Promise<void>
  loadMore: () => Promise<void>
  applyFilters: (filters?: Filters) => Promise<void> | Promise<any[]>
  throwFeedbackError: ThrowFeedbackErrorFn
}

export const parseQuery = (query: Query): string => {
  const { pagination, filters, sort, ...rest } = query
  const baseQuery: Record<string, any> = {
    ...rest,
  }

  if (pagination) {
    baseQuery.offset = pagination.offset
    baseQuery.limit = pagination.limit
  }

  const result = [
    ...Object.keys(baseQuery)
      .filter((item) => {
        if (!baseQuery[item] && baseQuery[item] !== 0) return false
        return true
      })
      .map(
        (item) =>
          `${encodeURIComponent(item)}=${encodeURIComponent(baseQuery[item])}`,
      ),
  ]
  if (filters)
    result.push(`filters=${encodeURIComponent(JSON.stringify(filters))}`)

  if (sort) {
    result.push(`sort[field]=${encodeURIComponent(sort.field)}`)
    result.push(`sort[order]=${encodeURIComponent(sort.order)}`)
  }

  return result.join('&')
}

const usePPAGetList = <DataType extends Record<any, any> = Record<any, any>>({
  dataKey,
  path,
  params,
  pagination: propPagination = { limit: 10, offset: 0 },
  filters,
  sort,
}: UsePPAGetListProps): UsePPAGetListData<DataType> => {
  const { fetchData, throwFeedbackError } = usePPA()

  const [pagination, setPagination] = useState<
    Pagination & { emptyReturn?: boolean }
  >({
    offset: propPagination.offset,
    limit: propPagination.limit,
  })

  const [data, setData] = useState<undefined | DataType[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<PPAError | undefined>()

  const fetchList: FetchDataFn<DataType> = async (fetchProps) => {
    setError(undefined)

    try {
      const url = `${path}?${parseQuery({
        ...params,
        pagination: fetchProps.pagination || pagination,
        filters: fetchProps?.filters || filters,
        sort: fetchProps?.sort || sort,
      })}`
      const fetch = await fetchData({
        method: 'GET',
        url,
      })

      const responseData = fetch?.response?.data[dataKey]
      if (fetch?.error && fetch?.error instanceof AxiosError) {
        throw new AxiosError(fetch.error.message)
      } else if (!responseData || !Array.isArray(responseData)) {
        throw new Error('Wrong data type in response.')
      }

      return responseData as DataType[]
    } catch (err: any) {
      console.error('usePPA.fetchList() error:', err)
      if (err instanceof AxiosError) {
        if (typeof err === 'object' && err?.response?.data?.errorType) {
          setError({
            errorType: err?.response?.data?.errorType,
            message: err?.response?.data?.message,
          })
        }
      } else {
        setError({
          errorType: 'UNHANDLED_RESPONSE_ERROR',
          message: 'The response error was not recognized.',
        })
      }
      return undefined
    }
  }

  const loadMore = async () => {
    if (!data || isLoading || pagination.emptyReturn) return
    const currentPagination = {
      offset: pagination.offset + pagination.limit,
      limit: pagination.limit,
    }

    const newData = await fetchList({
      dataKey,
      path,
      filters,
      sort,
      pagination: currentPagination,
    })

    let emptyReturn = false
    if (newData && newData.length === 0) {
      emptyReturn = true
    }

    setPagination({
      ...currentPagination,
      emptyReturn,
    })

    if (newData) {
      setData([...data, ...newData])
    }
  }

  const reset = async () => {
    setIsLoading(true)

    setPagination({
      offset: propPagination.offset,
      limit: propPagination.limit,
    })

    setData(
      await fetchList({
        dataKey,
        path,
        filters,
        sort,
        pagination: {
          offset: propPagination.offset,
          limit: propPagination.limit,
        },
      }),
    )
    setIsLoading(false)
  }

  const applyFilters = async (newFilters?: Filters): Promise<any[]> => {
    setIsLoading(true)
    setPagination({
      offset: propPagination.offset,
      limit: propPagination.limit,
    })

    const updatedData = await fetchList({
      dataKey,
      path,
      filters: newFilters,
      sort,
      pagination: {
        offset: propPagination.offset,
        limit: propPagination.limit,
      },
    })

    setData(updatedData)
    setIsLoading(false)

    return updatedData ?? []
  }

  useEffect(() => {
    const firstFetch = async () => {
      setIsLoading(true)
      setData(await fetchList({ dataKey, path, filters, sort }))
      setIsLoading(false)
    }

    firstFetch()
  }, [])

  return {
    data,
    isLoading,
    error,
    loadMore,
    reset,
    applyFilters,
    throwFeedbackError,
  }
}

export default usePPAGetList
