import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import usePPAGetList, {
  Filters,
} from '../../../../../hooks/use-ppa/use-ppa-get-list'

import PageTitle from '../../../../../components/atoms/page-title'
import Table, { TableConfigProps } from '../../../../../components/atoms/table'

import SearchFilterConsumerTenders from '../components/search-filter'

import { dateFormats, mappedMatchPercentage } from '../../../../../utils/data'

import {
  TenderMeter,
  ConsumerTender,
  TenderStatus,
  TenderConsumerInvitation,
  TenderInvitationStatus,
} from '../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

const rowKeys: TableConfigProps[] = [
  {
    keyName: 'shortId',
    renderCustomEl: (item: TenderMeter) => {
      return item.shortId
    },
    sortable: true,
  },
  {
    keyName: 'consumerMeter',
    renderCustomEl: (item: TenderMeter) => {
      if (!item.consumerMeter) return undefined
      return item.consumerMeter.name
    },
    sortable: true,
  },
  {
    keyName: 'mpan',
    renderCustomEl: (item: TenderMeter) => {
      if (!item.consumerMeter) return undefined
      return item.consumerMeter.mpan
    },
  },
  {
    keyName: 'startDate',
    renderCustomEl: (item: TenderMeter) => {
      return dayjs(item.startDate).format(dateFormats.user)
    },
    sortable: true,
  },
  {
    keyName: 'endDate',
    renderCustomEl: (item: TenderMeter) => {
      return dayjs(item.endDate).format(dateFormats.user)
    },
    sortable: true,
  },
  {
    keyName: 'deadline',
    renderCustomEl: (item: TenderMeter) => {
      if (!item?.deadline) return undefined

      return dayjs
        .utc(item.deadline)
        .tz('Europe/London')
        .format(dateFormats.userDateTime)
    },
    sortable: true,
  },
  {
    keyName: 'targetMatch',
    renderCustomEl: (item: TenderMeter) => {
      return mappedMatchPercentage[item.targetMatch]
    },
    sortable: true,
  },
  {
    keyName: 'annualConsumption',
    suffix: 'MWh',
    renderCustomEl: (item: TenderMeter) => {
      if (!item.consumerMeter) return undefined
      return item.consumerMeter.annualConsumption
    },
  },
]

const tenderStatus: TenderStatus = 'PENDING'
const tenderInvitationStatus: TenderInvitationStatus = 'CREATED'

const defaultFilters: Filters = [
  { field: 'status', operator: 'equals', value: tenderInvitationStatus },
  { field: 'consumerTenderStatus', operator: 'equals', value: tenderStatus },
]

const defaultSort: { key: string; direction: 'asc' | 'desc' } = {
  key: 'consumerMeter',
  direction: 'asc',
}

const ConsumerTendersPending: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.table',
  })

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const [sortData, setSortData] = useState<{
    key: string | null
    direction: 'asc' | 'desc' | null
  }>(defaultSort)

  const {
    applyFilters,
    isLoading,
    loadMore,
    throwFeedbackError,
    data,
    error,
    reset,
  } = usePPAGetList<TenderConsumerInvitation>({
    dataKey: 'consumerTenderInvitation',
    path: '/core/private/consumer-tender/supplier/list',
    filters,
    sort: sortData.key
      ? { field: sortData.key, order: sortData.direction! }
      : undefined,
  })

  const tenders = useMemo((): ConsumerTender[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data.map((invitation) => ({
      ...invitation.consumerTender,
    }))
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters([...defaultFilters, ...newFilters])
        setFilters([...defaultFilters, ...newFilters])
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter(
      (item) =>
        item.field === 'search' ||
        defaultFilters.some(
          (defaultFilter) => defaultFilter.field === item.field,
        ),
    )
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (sortData) {
      reset()
    }
  }, [sortData])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.pending')} />
      </div>
      <SearchFilterConsumerTenders
        onSubmit={fetchWithFilters}
        cleanFilters={handleCleanFilters}
      />
      <div className="relative z-10">
        <Table
          headers={[
            t('headers.tender'),
            t('headers.meter'),
            t('headers.mpan'),
            t('headers.startDate'),
            t('headers.endDate'),
            t('headers.deadline'),
            t('headers.targetMatch'),
            t('headers.annualConsumption'),
          ]}
          onRowClick={(item: ConsumerTender) => {
            navigate(`/consumerTenders/${item.id}`)
          }}
          rowKeys={rowKeys}
          loadMore={loadMore}
          isLoading={isLoading}
          data={tenders}
          defaultSortKey={defaultSort.key}
          defaultSortDirection={defaultSort.direction}
          onSort={(sortKey, sortDirection) =>
            setSortData({ key: sortKey, direction: sortDirection })
          }
        />
      </div>
    </div>
  )
}

export default ConsumerTendersPending
