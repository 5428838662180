import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'

import usePPAGetList, {
  Filters,
} from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import SelectAsync, {
  SelectAsyncProps,
} from '../../../../../../components/atoms/select-async'
import { SelectOptions } from '../../../../../../components/atoms/select'

import { capitalizeText } from '../../../../../../utils'

type TechnologyResponse = { name: string; active: true }

type SelectTechnologyProps = Omit<SelectAsyncProps, 'requestOptions'>

const SelectTechnologies: React.FC<SelectTechnologyProps> = ({ ...props }) => {
  const { t: tUtils } = useTranslation('private/index', { keyPrefix: 'utils' })

  const { isLoading, data, loadMore, applyFilters } =
    usePPAGetList<TechnologyResponse>({
      dataKey: 'technologies',
      path: '/core/private/default-values/technology',
      pagination: { limit: 50, offset: 0 },
      filters: [],
    })

  const [options, setOptions] = useState<SelectOptions[]>([])

  const loadOptions = useCallback(
    async (inputValue: string): Promise<SelectOptions[]> => {
      try {
        const filters: Filters =
          inputValue.trim() === ''
            ? []
            : [{ field: 'search', operator: 'contains', value: inputValue }]
        const newData = await applyFilters(filters)
        if (!newData) return []
        const sortedData = [...newData].sort((a, b) =>
          a.name.localeCompare(b.name),
        )
        return sortedData.map((technology) => {
          const techFullName = capitalizeText(
            tUtils(`technology.${technology.name}.fullName`),
          )
          return {
            value: technology.name,
            label: techFullName,
            key: technology.name,
          }
        })
      } catch (err) {
        console.error('Error querying technologies:', err)
        return []
      }
    },
    [applyFilters, tUtils],
  )

  const debouncedLoadOptionsInternal = useDebouncedCallback(
    (
      inputValue: string,
      resolve: (opts: SelectOptions[]) => void,
      reject: (error: any) => void,
    ) => {
      loadOptions(inputValue).then(resolve).catch(reject)
    },
    300,
  )

  const debouncedLoadOptions = useCallback(
    (inputValue: string): Promise<SelectOptions[]> => {
      return new Promise((resolve, reject) => {
        debouncedLoadOptionsInternal(inputValue, resolve, reject)
      })
    },
    [debouncedLoadOptionsInternal],
  )

  const handleMenuScrollToBottom = useCallback(async () => {
    if (!isLoading) {
      await loadMore()
      if (data) {
        const sortedData = [...data].sort((a, b) =>
          a.name.localeCompare(b.name),
        )
        const opts = sortedData.map((technology) => {
          const techFullName = capitalizeText(
            tUtils(`technology.${technology.name}.fullName`),
          )
          return {
            value: technology.name,
            label: techFullName,
            key: technology.name,
          }
        })
        setOptions(opts)
      }
    }
  }, [isLoading, loadMore, data, tUtils])

  const handleChange = useCallback(
    (selectedOption: any) => {
      props.onChange(selectedOption)
    },
    [props],
  )

  useEffect(() => {
    if (data) {
      const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name))
      const opts = sortedData.map((technology) => {
        const techFullName = capitalizeText(
          tUtils(`technology.${technology.name}.fullName`),
        )
        return {
          value: technology.name,
          label: techFullName,
          key: technology.name,
        }
      })
      setOptions(opts)
    }
  }, [data, tUtils])

  return (
    <SelectAsync
      {...props}
      value={props.value}
      type="single"
      testId="select-technology"
      loadOptions={debouncedLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onChange={handleChange}
      defaultOptions={options}
    />
  )
}

export default SelectTechnologies
