import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import usePPAGetList, {
  Filters,
} from '../../../../../hooks/use-ppa/use-ppa-get-list'

import PageTitle from '../../../../../components/atoms/page-title'
import Table, { TableConfigProps } from '../../../../../components/atoms/table'

import SearchFilter from '../components/search-filter'

import { capitalizeText } from '../../../../../utils'
import { dateFormats } from '../../../../../utils/data'

import { TenderSite, TenderInvitation } from '../../../../../types'

dayjs.extend(utc)
dayjs.extend(timezone)

type Tender = TenderInvitation & {
  tender: TenderSite
}

const rowKeys: TableConfigProps[] = [
  {
    keyName: 'shortId',
    renderCustomEl: (item: Tender) => {
      return item.tender.shortId
    },
    sortable: true,
  },
  {
    keyName: 'startDate',
    renderCustomEl: (item: Tender) => {
      return dayjs(item.tender.startDate).format(dateFormats.user)
    },
    sortable: true,
  },
  {
    keyName: 'endDate',
    renderCustomEl: (item: Tender) => {
      return dayjs(item.tender.endDate).format(dateFormats.user)
    },
    sortable: true,
  },
  {
    keyName: 'deadline',
    renderCustomEl: (item: Tender) => {
      if (item.tender?.deadline)
        return dayjs
          .utc(item.tender.deadline)
          .tz('Europe/London')
          .format(dateFormats.userDateTime)
      return undefined
    },
    sortable: true,
  },
  {
    keyName: 'site',
    renderCustomEl: (item: Tender) => {
      return item.tender.site.name
    },
    sortable: true,
  },
  {
    keyName: 'siteCapacity',
    suffix: 'MW',
    renderCustomEl: (item: Tender) => {
      return item.tender.site.capacity
    },
  },
]

const defaultFilters: Filters = [
  { field: 'status', operator: 'equals', value: 'EXECUTED' },
]

const defaultSort: { key: string; direction: 'asc' | 'desc' } = {
  key: 'deadline',
  direction: 'asc',
}

const TendersExecuted: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.table',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })
  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>(defaultFilters)

  const [sortData, setSortData] = useState<{
    key: string | null
    direction: 'asc' | 'desc' | null
  }>(defaultSort)

  const {
    applyFilters,
    isLoading,
    loadMore,
    throwFeedbackError,
    data,
    error,
    reset,
  } = usePPAGetList<TenderSite>({
    dataKey: 'tenderInvitations',
    path: '/core/private/tender/list-by-supplier',
    filters,
    sort: sortData.key
      ? { field: sortData.key, order: sortData.direction! }
      : undefined,
  })

  const tenders = useMemo((): TenderSite[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters([...defaultFilters, ...newFilters])
        setFilters([...defaultFilters, ...newFilters])
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter(
      (item) =>
        item.field === 'search' ||
        defaultFilters.some(
          (defaultFilter) => defaultFilter.field === item.field,
        ),
    )
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (sortData) {
      reset()
    }
  }, [sortData])

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.executed')} />
      </div>
      <SearchFilter
        onSubmit={fetchWithFilters}
        cleanFilters={handleCleanFilters}
      />
      <div className="relative z-10">
        <Table
          headers={[
            t('headers.tender'),
            t('headers.startDate'),
            t('headers.endDate'),
            t('headers.deadline'),
            t('headers.siteName'),
            t('headers.totalCapacity'),
            t('headers.technology'),
          ]}
          onRowClick={(item: Tender) => {
            navigate(`/tenders/${item.tender.id}`)
          }}
          rowKeys={[
            ...rowKeys,
            {
              keyName: 'tender.site.technology',
              renderCustomEl: (item: Tender) => {
                return capitalizeText(
                  tUtils(
                    `technology.${item.tender.site.technology}.abbreviation`,
                  ),
                )
              },
            },
          ]}
          loadMore={loadMore}
          isLoading={isLoading}
          data={tenders}
          defaultSortKey={defaultSort.key}
          defaultSortDirection={defaultSort.direction}
          onSort={(sortKey, sortDirection) =>
            setSortData({ key: sortKey, direction: sortDirection })
          }
        />
      </div>
    </div>
  )
}

export default TendersExecuted
