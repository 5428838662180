import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import { SleevedTenderDetailsProps } from '../details'

import TenderDetails from '../components/details'
import BidDetails, { ButtonList } from '../components/details-bid'
import CreateOrUpdateBid from '../components/bid-create-update'
import SleevedConsumerTenderDetailsDefList from '../components/details-consumer-tender'
import MeterDetailsDefList from '../components/details-meter'
import HistoricalHhdDownload from '../../../../../components/organisms/historical-hhd-download'

const ActiveSleevedTenderDetails: React.FC<SleevedTenderDetailsProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details',
  })

  const {
    isLoading,
    refetch: bidRefetch,
    throwFeedbackError,
    data,
    error,
  } = usePPAGetDetails<any>({
    dataKey: 'supplierTenderBid',
    path: `/core/private/supplier-tender-bid/supplier/details/${tenderInvitation?.supplierTenderBid?.id}`,
  })

  const bidDetails: any | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const [isBidDrawerOpen, setIsBidDrawerOpen] = useState(false)

  const handleCloseDrawer = () => setIsBidDrawerOpen(false)
  const handleOnSuccessDrawer = () => {
    setIsBidDrawerOpen(false)
    bidRefetch()
    refetch()
  }

  const buttons = useMemo((): ButtonList[] => {
    if (!tenderInvitation.supplierTender) return []
    let buttonText: string
    let variant: 'primary' | 'secondary' = 'primary'

    if (bidDetails) {
      if (bidDetails.status === 'ACTIVE') {
        buttonText = t('updateBid.button')
        variant = 'secondary'
      } else if (bidDetails.status === 'REJECTED') {
        buttonText = t('editBid.button')
        variant = 'secondary'
      } else {
        return []
      }
    } else {
      buttonText = t('placeBid.button')
    }

    return [
      {
        onClick: () => setIsBidDrawerOpen(true),
        props: {
          variant,
        },
        text: buttonText,
      },
    ]
  }, [bidDetails])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  return (
    <section
      className={classNames(
        'flex flex-col',
        '2xl:flex-row 2xl:gap-x-5',
        'w-full',
      )}
    >
      <div className="2xl:w-1/2">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
      <div
        className={classNames(
          '2xl:w-1/2',
          'flex flex-col gap-y-2',
          '2xl:border-l 2xl:mt-10 2xl:pl-10',
        )}
      >
        <BidDetails
          tender={tenderInvitation.supplierTender}
          bid={bidDetails}
          buttons={buttons}
          refetchBidList={refetch}
        />
        <div>
          <SleevedConsumerTenderDetailsDefList
            data={tenderInvitation.supplierTender.consumerTender as any}
          />
          <MeterDetailsDefList
            data={
              tenderInvitation.supplierTender.consumerTender
                .consumerMeter as any
            }
          />
        </div>
      </div>

      {tenderInvitation && (
        <CreateOrUpdateBid
          isDrawerOpen={isBidDrawerOpen}
          tenderInvitation={tenderInvitation}
          bid={bidDetails}
          onSuccess={handleOnSuccessDrawer}
          closeDrawer={handleCloseDrawer}
        />
      )}
    </section>
  )
}

export default ActiveSleevedTenderDetails
