import { Navigate, Route } from 'react-router-dom'

import { isFeatureEnabled } from '../../../utils/features'

import { ReactComponent as SiteSVG } from '../assets/Site.svg'
import { ReactComponent as TenderSVG } from '../assets/Tender.svg'
import { ReactComponent as SleevedTenderSVG } from '../assets/SleevedTender.svg'
import { ReactComponent as QuoteSVG } from '../assets/Quote.svg'
import { ReactComponent as DocumentSVG } from '../assets/Document.svg'
import { ReactComponent as MarketSVG } from '../assets/Market.svg'

import { sitesLinks, sitesSubRoutes } from './sites/routes'

import Tenders from './export-tenders'
import TendersDetails from './export-tenders/details'
import { tendersLinks, tendersSubRoutes } from './export-tenders/routes'

import SleevedTenders from './sleeved-tenders'
import SleevedTenderDetails from './sleeved-tenders/details'
import {
  sleevedTendersLinks,
  sleevedTendersSubRoutes,
} from './sleeved-tenders/routes'

import Quote from '../../../components/organisms/quote'
import Documents from './documents'
import Market from '../market'
import { LinksArray } from '../routes'
import NotFound from '../not-found'
import Notifications from '../notifications'
import Settings from './settings'

export const generatorLinks: LinksArray = [
  { to: '/sites', label: 'sites', icon: SiteSVG, subMenu: sitesLinks },
  {
    to: '/tenders',
    label: 'export',
    icon: TenderSVG,
    subMenu: tendersLinks,
  },
  {
    to: '/sleevedTenders',
    label: 'sleeved',
    icon: SleevedTenderSVG,
    subMenu: sleevedTendersLinks,
  },
  {
    to: '/quote',
    label: 'quote',
    icon: QuoteSVG,
  },
  { to: '/market', label: 'market', icon: MarketSVG },
]

if (isFeatureEnabled('documentsPage')) {
  generatorLinks.push({
    to: '/documents',
    label: 'documents',
    icon: DocumentSVG,
  })
}

export const generatorRoutes = [
  { path: '/', element: <Navigate to="/sites" /> },
  {
    path: '/sites',
    children: (
      <>
        <Route index element={<Navigate to="/sites/portfolio" />} />
        <Route path="portfolio" element={sitesSubRoutes.portfolio.index} />
        <Route path=":siteId" element={sitesSubRoutes.portfolio.details} />
        <Route path="companies" element={sitesSubRoutes?.companies?.index} />
        <Route
          path="/sites/companies/:companyId"
          element={sitesSubRoutes?.companies?.details}
        />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/tenders',
    children: (
      <>
        <Route index element={<Navigate to="/tenders/status/active" />} />
        {Object.keys(tendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/tenders/status/${key}`}
              element={<Tenders status={key} />}
            />
            <Route path="/tenders/:tenderId" element={<TendersDetails />} />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/sleevedTenders',
    children: (
      <>
        <Route
          index
          element={<Navigate to="/sleevedTenders/status/accepted" />}
        />
        {Object.keys(sleevedTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/sleevedTenders/status/${key}`}
              element={<SleevedTenders status={key} />}
            />
            <Route
              path="/sleevedTenders/:tenderId"
              element={<SleevedTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  { path: '/quote', element: <Quote /> },
  { path: '/documents', element: <Documents /> },
  { path: '/market', element: <Market /> },
  { path: '/settings', element: <Settings /> },
]

if (isFeatureEnabled('notificationsPage')) {
  generatorRoutes.push({
    path: '/notifications',
    element: <Notifications />,
  })
}
