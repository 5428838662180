import { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import dayjs from 'dayjs'
import classNames from 'classnames'

import { transition } from '../../../styles'

import useUser from '../../../hooks/use-user'

import { dateFormats } from '../../../utils/data'

import RequiredField from '../../atoms/required-form-field'
import InputGroup from '../../molecules/input-group'
import Checkbox from '../../atoms/checkbox'
import Input from '../../atoms/input'
import Button from '../../atoms/button'
import Select, { SelectOptions } from '../../atoms/select'
import Message from '../../atoms/message'

import SelectSite from '../../../pages/private/generator/export-tenders/components/select-sites'

import { optionsDurations } from '../../../pages/private/generator/export-tenders/components/form-tender'

export type FormValues = {
  site?: SelectOptions
  startDate?: string
  endDate?: string
  durationMonths?: string
  customEndDate?: boolean
  subsidies: {
    fit: { enabled: false }
    rego: { enabled: true }
    roc: { enabled: false }
  }
}

interface QuoteFormProps {
  onSubmit: (data: any) => void
  isLoading?: boolean
  defaultValues?: FormValues
}

const formDefaultValues: FormValues = {
  site: undefined,
  startDate:
    dayjs().get('hours') < 10
      ? dayjs().add(1, 'week').format(dateFormats.aws)
      : dayjs().add(8, 'days').format(dateFormats.aws),
  durationMonths: undefined,
  customEndDate: true,
  endDate: '',
  subsidies: {
    fit: { enabled: false },
    rego: { enabled: true },
    roc: { enabled: false },
  },
}

export type TenderFormRef = {
  resetForm: () => void
}

const QuoteForm: React.FC<QuoteFormProps> = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.quote.form',
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    register,
    setValue,
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  const { user } = useUser<'GENERATOR' | 'ADMIN'>()

  const minStartDate = useMemo(() => {
    if (user?.adminActing?.userId) {
      return null
    }
    const now = dayjs()
    return now.get('hours') < 10
      ? now.add(1, 'week').format(dateFormats.aws)
      : now.add(8, 'days').format(dateFormats.aws)
  }, [user])

  const minEndDate = useMemo(() => {
    const startDate = watch('startDate')
    if (!startDate) return ''
    return dayjs(startDate)
      .add(1, 'month')
      .endOf('month')
      .format(dateFormats.aws)
  }, [watch('startDate')])

  const labelEndDate = useMemo(() => {
    const undefinedLabel = 'dd/mm/yyyy'

    const startDate = watch('startDate')
    const endDate = watch('endDate')
    const customEndDate = watch('customEndDate')
    if (!startDate) return undefinedLabel

    if (customEndDate) {
      if (!endDate) return undefinedLabel
      return dayjs(endDate).format(dateFormats.user)
    }

    const duration = watch('durationMonths')
    if (!duration) return undefinedLabel
    const durationInt = parseInt(duration, 10)

    return dayjs(startDate)
      .add(durationInt, 'months')
      .subtract(1, 'month')
      .endOf('month')
      .format(dateFormats.user)
  }, [
    watch('startDate'),
    watch('endDate'),
    watch('customEndDate'),
    watch('durationMonths'),
  ])

  useEffect(() => {
    const customEndDate = watch('customEndDate')
    if (customEndDate) {
      register('endDate')
    } else {
      register('durationMonths')
    }
  }, [watch('customEndDate')])

  const selectedSite = watch('site')

  const isRocDisabled = useMemo(() => {
    if (!selectedSite) return false

    const parsedSite = JSON.parse(selectedSite.value)

    return parsedSite.rocBand === null
  }, [selectedSite])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-5 w-auto max-w-lg"
    >
      <Controller
        name="site"
        control={control}
        rules={{
          required: {
            value: true,
            message: t('site.required'),
          },
        }}
        render={({ field: { ref: _, ...props } }) => (
          <InputGroup
            label={t('site.title')}
            error={errors.site?.message}
            required
          >
            <SelectSite
              {...props}
              type="single"
              placeholder={t('site.placeholder')}
            />
          </InputGroup>
        )}
      />

      <div className="flex flex-col gap-y-2">
        <div className="flex items-start gap-x-6">
          <div className="flex flex-1">
            <Controller
              name="startDate"
              control={control}
              rules={{
                required: { value: true, message: t('startDate.required') },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('startDate.title')}
                  error={errors.startDate?.message}
                  required
                >
                  <Input
                    type="date"
                    {...props}
                    min={minStartDate}
                    placeholder={t('startDate.placeholder')}
                    error={errors.startDate?.message}
                  />
                </InputGroup>
              )}
            />
          </div>

          <div className="flex flex-1">
            {watch('customEndDate') ? (
              <Controller
                name="endDate"
                control={control}
                rules={{
                  required: { value: true, message: t('endDate.required') },
                  validate: {
                    endOfMonth: (value) => {
                      if (!value) {
                        return t('endDate.required')
                      }

                      const endDateEndOfMonth = dayjs(value)
                        .endOf('month')
                        .format(dateFormats.aws)

                      if (value !== endDateEndOfMonth) {
                        return t('endDate.ruleEndOfMonth')
                      }
                      return undefined
                    },
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('endDate.title')}
                    error={errors.endDate?.message}
                    required
                  >
                    <Input
                      type="date"
                      {...props}
                      min={minEndDate}
                      placeholder={t('endDate.placeholder')}
                      error={errors.endDate?.message}
                    />
                  </InputGroup>
                )}
              />
            ) : (
              <Controller
                name="durationMonths"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('durationMonths.required'),
                  },
                }}
                render={({ field: { ref: _, ...props } }) => (
                  <InputGroup
                    label={t('durationMonths.title')}
                    error={errors.durationMonths?.message}
                    required
                  >
                    <Select
                      {...props}
                      placeholder="0"
                      options={optionsDurations}
                      error={errors.durationMonths?.message}
                    />
                  </InputGroup>
                )}
              />
            )}
          </div>
        </div>

        <div className="flex items-end justify-start text-ppa/title">
          <div
            className={classNames(
              'flex items-center justify-start mr-1',
              watch('customEndDate')
                ? 'opacity-0 pointer-events-none select-none absolute -top-full'
                : 'opacity-100',
              transition,
            )}
          >
            <span>{t('endDate.label')}</span>
            &nbsp;
            <strong>{labelEndDate}</strong>
          </div>
          {watch('customEndDate') ? (
            <button
              type="button"
              className={classNames(
                transition,
                'text-ppa/primary underline font-medium text-sm hover:brightness-75 cursor-pointer',
              )}
              onClick={() => {
                setValue('customEndDate', false)
              }}
            >
              {t('endDate.enableDuration')}
            </button>
          ) : (
            <button
              type="button"
              className={classNames(
                transition,
                'text-ppa/primary underline font-medium text-sm hover:brightness-75 cursor-pointer',
              )}
              onClick={() => {
                setValue('customEndDate', true)
                setValue('endDate', '')
              }}
            >
              {t('endDate.enableCustomEndDate')}
            </button>
          )}
        </div>
      </div>

      <InputGroup label={t('subsidies.title')}>
        {isRocDisabled && (
          <span className="my-2">
            <Message variant="info" size="text-xs self-center">
              {t('subsidies.infoMessage')}
            </Message>
          </span>
        )}
        <div className="flex items-center gap-3">
          <Controller
            name="subsidies.rego.enabled"
            control={control}
            render={({ field: props }) => <Checkbox {...props} label="REGO" />}
          />

          <Controller
            name="subsidies.roc.enabled"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                label="ROC"
                {...props}
                disabled={
                  isRocDisabled ||
                  (watch('subsidies.fit.enabled') &&
                    !watch('subsidies.roc.enabled'))
                }
              />
            )}
          />

          <Controller
            name="subsidies.fit.enabled"
            control={control}
            render={({ field: props }) => (
              <Checkbox
                label="FIT"
                {...props}
                disabled={
                  watch('subsidies.roc.enabled') &&
                  !watch('subsidies.fit.enabled')
                }
              />
            )}
          />
        </div>
      </InputGroup>

      <div>
        <RequiredField />
      </div>

      <div className="flex items-center w-full gap-x-5">
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default QuoteForm
