import React, { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import usePPAGetList, {
  Filters,
} from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import SelectAsync, {
  SelectAsyncProps,
} from '../../../../../../components/atoms/select-async'
import { SelectOptions } from '../../../../../../components/atoms/select'

import { SupplierWithProfile } from '../../../../../../types'

type SelectSuppliersProps = Omit<SelectAsyncProps, 'requestOptions'>

const SelectSuppliers: React.FC<SelectSuppliersProps> = ({
  value,
  ...props
}) => {
  const { isLoading, data, loadMore, applyFilters } =
    usePPAGetList<SupplierWithProfile>({
      dataKey: 'suppliers',
      path: '/core/private/consumer-tender/consumer/participating-suppliers',
      pagination: { limit: 50, offset: 0 },
      filters: [],
    })

  const [options, setOptions] = useState<SelectOptions[]>([])

  const selectedSuppliers = value || []

  const loadOptions = useCallback(
    async (inputValue: string): Promise<SelectOptions[]> => {
      try {
        const filters: Filters =
          inputValue.trim() === ''
            ? []
            : [{ field: 'search', operator: 'contains', value: inputValue }]

        const newData = await applyFilters(filters)
        const selectedValues = selectedSuppliers.map((selected: any) =>
          typeof selected === 'string' ? JSON.parse(selected) : selected,
        )
        const supp = (newData || []).filter(
          (supplier: SupplierWithProfile) =>
            !selectedValues.some(
              (values: any) => values.name === supplier.name,
            ),
        )
        return supp
          .map((supplier: SupplierWithProfile) => ({
            value: JSON.stringify({ id: supplier.id }),
            label: supplier.name,
            key: supplier.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
      } catch (err) {
        console.error(err)
        return []
      }
    },
    [applyFilters, selectedSuppliers],
  )

  const debouncedLoadOptionsInternal = useDebouncedCallback(
    (
      inputValue: string,
      resolve: (opts: SelectOptions[]) => void,
      reject: (error: any) => void,
    ) => {
      loadOptions(inputValue).then(resolve).catch(reject)
    },
    300,
  )

  const debouncedLoadOptions = useCallback(
    (inputValue: string): Promise<SelectOptions[]> => {
      return new Promise((resolve, reject) => {
        debouncedLoadOptionsInternal(inputValue, resolve, reject)
      })
    },
    [debouncedLoadOptionsInternal],
  )

  const handleMenuScrollToBottom = useCallback(async () => {
    if (!isLoading) {
      await loadMore()
      if (data) {
        const selectedValues = selectedSuppliers.map((selected: any) =>
          typeof selected === 'string' ? JSON.parse(selected) : selected,
        )
        const available = (data || []).filter(
          (supplier: SupplierWithProfile) =>
            !selectedValues.some((sel: any) => sel.name === supplier.name),
        )
        const formatted = available
          .map((supplier: SupplierWithProfile) => ({
            value: JSON.stringify({ id: supplier.id }),
            label: supplier.name,
            key: supplier.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
        setOptions(formatted)
      }
    }
  }, [isLoading, loadMore, data, selectedSuppliers])

  const handleChange = useCallback(
    (selectedOption: any) => {
      if (selectedOption) {
        if (Array.isArray(selectedOption)) {
          const selectedArray = selectedOption.map((option: any) => ({
            value: option.value,
            label: option.label,
          }))
          props.onChange(selectedArray)
        } else {
          props.onChange({
            value: selectedOption.value,
            label: selectedOption.label,
          })
        }
      } else {
        props.onChange(null)
        applyFilters([])
      }
    },
    [props, applyFilters],
  )

  useEffect(() => {
    if (data) {
      const selectedValues = selectedSuppliers.map((selected: any) =>
        typeof selected === 'string' ? JSON.parse(selected) : selected,
      )
      const available = (data || []).filter(
        (supplier: SupplierWithProfile) =>
          !selectedValues.some((sel: any) => sel.name === supplier.name),
      )
      const formatted = available
        .map((supplier: SupplierWithProfile) => ({
          value: JSON.stringify({ id: supplier.id }),
          label: supplier.name,
          key: supplier.name,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))
      setOptions(formatted)
    }
  }, [data, selectedSuppliers])

  return (
    <SelectAsync
      {...props}
      value={value}
      type="multi"
      testId="select-suppliers"
      loadOptions={debouncedLoadOptions}
      onMenuScrollToBottom={handleMenuScrollToBottom}
      onChange={handleChange}
      defaultOptions={options}
    />
  )
}

export default SelectSuppliers
