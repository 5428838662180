import { Navigate, Route } from 'react-router-dom'

import { isFeatureEnabled } from '../../../utils/features'

import { ReactComponent as SupplierSVG } from '../assets/Supplier.svg'
import { ReactComponent as GeneratorSVG } from '../assets/Generator.svg'
import { ReactComponent as ConsumerSVG } from '../assets/Consumer.svg'
import { ReactComponent as TenderSVG } from '../assets/Tender.svg'
import { ReactComponent as ConsumerTenderSVG } from '../assets/ConsumerTender.svg'
import { ReactComponent as SleevedTenderSVG } from '../assets/SleevedTender.svg'
import { ReactComponent as MarketSVG } from '../assets/Market.svg'
import { ReactComponent as SiteSVG } from '../assets/Site.svg'
import { ReactComponent as MeterSVG } from '../assets/Meter.svg'
import { ReactComponent as QuoteSVG } from '../assets/Quote.svg'

import Market from '../market'

import Suppliers from './supplier'
import SupplierDetails from './supplier/details'

import Generators from './generator'
import GeneratorDetails from './generator/details'

import Consumers from './consumer'
import ConsumerDetails from './consumer/details'

import Sites from './sites'
import SiteDetails from './sites/details'

import Meters from './meters'
import MeterDetails from './meters/details'

import Tenders from './export-tenders'
import TenderDetails from './export-tenders/details'
import { tendersLinks, tendersSubRoutes } from './export-tenders/routes'

import ConsumerTenders from './consumer-tenders'
import ConsumerTenderDetails from './consumer-tenders/details'
import {
  consumerTendersLinks,
  consumerTendersSubRoutes,
} from './consumer-tenders/routes'

import SleevedTenders from './sleeved-tenders'
import SleevedTenderDetails from './sleeved-tenders/details'
import {
  sleevedTendersLinks,
  sleevedTendersSubRoutes,
} from './sleeved-tenders/routes'

import Quote from '../../../components/organisms/quote'
import NotFound from '../not-found'
import Notifications from '../notifications'
import Settings from './settings'

export const staffAdminLinks = [
  { to: '/suppliers', label: 'suppliers', icon: SupplierSVG },
  { to: '/generators', label: 'generators', icon: GeneratorSVG },
  { to: '/consumers', label: 'consumers', icon: ConsumerSVG },
  { to: '/sites', label: 'sites', icon: SiteSVG },
  {
    to: '/meters',
    label: 'meters',
    icon: MeterSVG,
  },
  {
    to: '/tenders',
    label: 'export',
    icon: TenderSVG,
    subMenu: tendersLinks,
  },
  {
    to: '/sleevedTenders',
    label: 'sleeved',
    icon: SleevedTenderSVG,
    subMenu: sleevedTendersLinks,
  },
  {
    to: '/consumerTenders',
    label: 'import',
    icon: ConsumerTenderSVG,
    subMenu: consumerTendersLinks,
  },
  {
    to: '/quote',
    label: 'quote',
    icon: QuoteSVG,
  },
  { to: '/market', label: 'market', icon: MarketSVG },
]

export const adminRoutes = [
  { path: '/', element: <Navigate to="/generators" /> },
  {
    path: '/suppliers',
    children: (
      <>
        <Route index element={<Suppliers />} />
        <Route path=":supplierId" element={<SupplierDetails />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/generators',
    children: (
      <>
        <Route index element={<Generators />} />
        <Route path=":generatorId" element={<GeneratorDetails />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/consumers',
    children: (
      <>
        <Route index element={<Consumers />} />
        <Route path=":consumerId" element={<ConsumerDetails />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  { path: '/', element: <Navigate to="/sites" /> },
  {
    path: '/sites',
    children: (
      <>
        <Route index element={<Sites />} />
        <Route path=":siteId" element={<SiteDetails />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/meters',
    children: (
      <>
        <Route index element={<Meters />} />
        <Route path=":meterId" element={<MeterDetails />} />
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/tenders',
    children: (
      <>
        <Route index element={<Navigate to="/tenders/status/active" />} />
        {Object.keys(tendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/tenders/status/${key}`}
              element={<Tenders status={key} />}
            />
            <Route path="/tenders/:tenderId" element={<TenderDetails />} />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/sleevedTenders',
    children: (
      <>
        <Route
          index
          element={<Navigate to="/sleevedTenders/status/accepted" />}
        />
        {Object.keys(sleevedTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/sleevedTenders/status/${key}`}
              element={<SleevedTenders status={key} />}
            />
            <Route
              path="/sleevedTenders/:tenderId"
              element={<SleevedTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  {
    path: '/consumerTenders',
    children: (
      <>
        <Route
          index
          element={<Navigate to="/consumerTenders/status/active" />}
        />
        {Object.keys(consumerTendersSubRoutes).map((key: any) => (
          <Route key={key}>
            <Route
              path={`/consumerTenders/status/${key}`}
              element={<ConsumerTenders status={key} />}
            />
            <Route
              path="/consumerTenders/:tenderId"
              element={<ConsumerTenderDetails />}
            />
          </Route>
        ))}
        <Route path="*" element={<NotFound />} />
      </>
    ),
  },
  { path: '/quote', element: <Quote /> },
  { path: '/market', element: <Market /> },
  { path: '/settings', element: <Settings /> },
]

if (isFeatureEnabled('notificationsPage')) {
  adminRoutes.push({ path: '/notifications', element: <Notifications /> })
}
